import classNames from 'classnames'
import Croppie from 'croppie'
import 'croppie/croppie.css'
import { useEffect, useRef, useState } from 'react'
import { Button } from './Button'
import { Icons } from './Icons'
import { Modal } from './Modal'

interface ImagePickerProps {
  image?: string
  onCrop: (blob: Blob) => void
  onClear: () => void
}

async function readAsDataURL(blob: Blob) {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader()

    const read = () => resolve(reader.result)
    reader.addEventListener('load', read)
    reader.readAsDataURL(blob)
  })
}

async function loadImage() {
  return new Promise<File | undefined>((resolve) => {
    const fileInput = document.createElement('input')
    fileInput.setAttribute('type', 'file')
    fileInput.setAttribute('accept', 'image/*')
    fileInput.click()
    fileInput.remove()

    fileInput.addEventListener('change', async (event) => {
      const image = (event.target as HTMLInputElement).files?.[0]
      resolve(image)
    })
  })
}

type Dimension = { width: number; height: number }
const viewport: Dimension = { width: 300, height: 300 }
const boundary: Dimension = { width: 400, height: 400 }
const size: Dimension = { width: 400, height: 400 }

export const ImagePicker = ({
  image: initialImage = '',
  onCrop,
  onClear,
}: ImagePickerProps) => {
  const [file, setFile] = useState<File | undefined>()
  const [cropping, setCropping] = useState(false)
  const [image, setImage] = useState(initialImage)

  const divRef = useRef<HTMLDivElement>(null)
  const croppieRef = useRef<Croppie>()

  async function handleUploadClick() {
    const file = await loadImage()
    setFile(file)
  }

  useEffect(() => {
    if (!file) return
    ;(async () => {
      const div = divRef.current
      if (!div) return

      const croppie = new Croppie(div, { viewport, boundary })
      croppieRef.current = croppie

      const dataUrl = await readAsDataURL(file)
      croppie.bind({ url: String(dataUrl), zoom: 0 })
      setCropping(true)
    })()
  }, [file])

  async function handleCropClick() {
    const croppie = croppieRef.current
    if (!croppie) return
    const result = await croppie.result({ size, type: 'blob' })

    const imageString = String(await readAsDataURL(result))
    setImage(imageString)
    setCropping(false)
    setFile(undefined)
    onCrop(result)
  }

  function handleClearClick() {
    setImage('')
    onClear()
  }

  return (
    <div className="space-y-2">
      <label className="block">Immagine</label>
      <div>
        {file && (
          <Modal>
            <div className="w-full h-full flex justify-center items-center">
              <div className="bg-white p-10 flex justify-center flex-col items-center">
                <div>
                  <div ref={divRef} />
                </div>
                <Button type="button" onClick={handleCropClick}>
                  Salva immagine
                </Button>
              </div>
            </div>
          </Modal>
        )}

        <img
          className={classNames((cropping || !image) && 'hidden', 'border')}
          src={image}
          alt=""
          style={{ width: 200, height: 200 }}
        />

        <Button
          className={classNames(
            'border flex items-center justify-center',
            (cropping || image) && 'hidden'
          )}
          type="button"
          style={{ width: 200, height: 200 }}
          onClick={handleUploadClick}
        >
          <Icons.photoAdd className="opacity-20" />
        </Button>
      </div>
      <div>
        {image && (
          <Button type="button" onClick={handleClearClick} icon="delete">
            Elimina
          </Button>
        )}
      </div>
    </div>
  )
}
