import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { GENDERS } from '../lib/Api'
import { useCoolForm } from '../lib/hooks/useCoolForm'
import { Button } from './ui/Button'
import { ImagePicker } from './ui/ImagePicker'
import { Input } from './ui/Input'
import { Select } from './ui/Select'

interface FormUserConfirm {
  name: string
  surname: string
  image?: Blob | string
  gender: string
}

interface FormUserProps {
  name?: string
  surname?: string
  image?: string
  gender?: string
  onSubmit?: (data: FormUserConfirm) => void
  onCancel?: () => void
}

export const FormUser = observer(
  ({
    onSubmit: onConfirm,
    onCancel,
    name: initialName = '',
    surname: initialSurname = '',
    gender: initialGender = '',
    image: initialImage,
  }: FormUserProps) => {
    const [image, setImage] = useState<Blob | string | undefined>(initialImage)
    const { register, handleSubmit, errors } = useCoolForm({
      defaultValues: {
        name: initialName,
        surname: initialSurname,
        gender: initialGender,
      },
      errors: (values) => ({
        name: !values.name ? { message: 'Aggiungi il nome' } : undefined,
        surname: !values.surname
          ? { message: 'Aggiungi il cognome' }
          : undefined,
        gender: !values.gender ? { message: 'Scegli Uomo o Donna' } : undefined,
      }),
    })

    const onSubmit = handleSubmit(async (data) => {
      onConfirm?.({
        name: data.name,
        surname: data.surname,
        image: image,
        gender: data.gender,
      })
    })

    return (
      <form onSubmit={onSubmit} className="">
        <div className="flex md:space-x-5 space-y-5 space-y-reverse flex-col-reverse md:flex-row">
          <div className="md:w-2/3">
            <Input
              label="Nome"
              {...register('name')}
              error={errors.name?.message}
            />
            <Input
              label="Cognome"
              {...register('surname')}
              error={errors.surname?.message}
            />

            <Select
              label="Uomo/Donna"
              {...register('gender')}
              error={errors.gender?.message}
            >
              {GENDERS.map((g) => (
                <option key={g} value={g}>
                  {g}
                </option>
              ))}
            </Select>
          </div>

          <ImagePicker
            image={initialImage}
            onCrop={(blob) => setImage(blob)}
            onClear={() => setImage(undefined)}
          />
        </div>

        <div className="space-x-4">
          <Button type="submit">Salva</Button>
          {onCancel && <Button onClick={onCancel}>Annulla</Button>}
        </div>
      </form>
    )
  }
)
