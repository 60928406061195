import { useState } from 'react'
import { success } from '../lib/async-utils'
import { Input } from '../components/ui/Input'
import { Button } from '../components/ui/Button'
import Api from '../lib/Api'
import { useCoolForm } from '../lib/hooks/useCoolForm'
import { Path } from '../lib/constants'
import { TextLink } from '../components/ui/LinkText'

type FormValues = {
  email: string
  password: string
}

export const LoginPage = () => {
  const [invalidCredential, setInvalidCredential] = useState(false)
  const { register, handleSubmit, errors } = useCoolForm<FormValues>({
    errors: (values) => ({
      email: !values.email
        ? {
            type: 'required',
            message: 'Inserisci la mail',
          }
        : undefined,
      password: !values.password
        ? {
            type: 'required',
            message: 'Inserisci la password',
          }
        : undefined,
    }),
  })

  const onSubmit = handleSubmit(async (data) => {
    const resolved = await success(Api.signIn(data.email, data.password))
    setInvalidCredential(!resolved)
  })

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <form
        className="md:border p-4 md:shadow max-w-lg w-full"
        onSubmit={onSubmit}
      >
        <div className="text-center font-bold text-2xl">Accedi</div>
        <Input
          {...register('email')}
          label="Email"
          type="email"
          placeholder="email"
          error={errors.email?.message}
        />

        <Input
          {...register('password')}
          label="Password"
          type="password"
          placeholder="password"
          error={errors.password?.message}
        />

        {invalidCredential && (
          <p className="text-red-500">La mail o la password sono errate</p>
        )}
        <Button type="submit">Accedi</Button>

        <div className="mt-6">
          <TextLink to={Path.editPassword}>Ho dimenticato la password</TextLink>
        </div>
      </form>
    </div>
  )
}
