import { observer } from 'mobx-react-lite'
import { Canvg } from 'canvg'
import { useUser } from '../state'
import { TeamInstance } from '../state/Team'
import { Button } from './ui/Button'
import { useRef } from 'react'

import { USER_PROFILE_IMG_URL } from '../lib/constants'
export async function downloadDomElementImage(
  element: HTMLDivElement,
  filename: string
) {
  const canvas = document.createElement('canvas')
  canvas.width = 1000
  canvas.height = 1000

  const ctx = canvas.getContext('2d')!

  await Canvg.fromString(ctx, element.innerHTML).render()

  const blobImage = canvas.toDataURL('image/png', 1.0)
  canvas.remove()

  const link = window.document.createElement('a')
  link.setAttribute('style', 'display: none;')
  link.download = `${filename}.png`
  link.href = blobImage
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  link.remove()
}

export interface TeamImageProps {
  team: TeamInstance
}

export const TeamImage = observer(({ team }: TeamImageProps) => {
  const width = 400
  const height = 400

  const user = useUser()!

  const players = [
    { name: user.name, image: user.image },
    ...team.players.map((p) => ({ image: p.image, name: p.name })),
  ].map((p, i) => {
    return {
      ...p,
      width: width / 3,
      height: height / 3,
      x: ((i % 3) * width) / 3,
      y: (Math.floor(i / 3) * height) / 3,
    }
  })

  const elRef = useRef<HTMLDivElement>(null)

  return (
    <div>
      <div ref={elRef}>
        <svg
          style={{ maxWidth: 500, margin: '0 auto' }}
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width={'100%'} height={'100%'} fill="white" />

          {players.map((p, i) => {
            return (
              <>
                <image
                  key={i}
                  href={p.image.url ?? USER_PROFILE_IMG_URL}
                  width={p.width}
                  height={p.height}
                  x={p.x}
                  y={p.y}
                />

                <rect
                  x={p.x + 5}
                  y={p.y + p.height * 0.85}
                  width={p.width - 10}
                  height={p.height * 0.3}
                  fill="white"
                  opacity={0.75}
                  rx={10}
                />
                <text
                  key={i}
                  href={p.image.url ?? USER_PROFILE_IMG_URL}
                  width={p.width}
                  height={p.height}
                  textAnchor="middle"
                  x={p.x + p.width / 2}
                  y={p.y + p.height * 0.95}
                  fontSize={10}
                >
                  {p.name}
                </text>
              </>
            )
          })}
        </svg>
      </div>

      <Button
        onClick={() => downloadDomElementImage(elRef.current!, 'squadra')}
      >
        Scarica
      </Button>
    </div>
  )
})
