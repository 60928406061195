import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, ButtonProps } from './Button'
import { Dialog, DialogProps } from './Dialog'

interface ButtonWithDialogProps extends ButtonProps {
  dialog: Omit<DialogProps, 'onCancel'>
}
export const ButtonWithDialog = observer(
  ({ dialog, ...buttonProps }: ButtonWithDialogProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const open = () => setIsModalOpen(true)
    const close = () => setIsModalOpen(false)
    return (
      <>
        <Button {...buttonProps} onClick={open} />
        {isModalOpen && <Dialog {...dialog} onCancel={close} />}
      </>
    )
  }
)
