import classNames from 'classnames'

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  preset?: 'primary' | 'secondary'
}

export const Title = ({ preset = 'primary', ...props }: TitleProps) => (
  <div
    {...props}
    className={classNames(
      'font-bold text-xl my-2',
      props.className,
      preset === 'primary' && 'text-xl',
      preset === 'secondary' && 'text-lg'
    )}
  />
)
