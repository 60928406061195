export const strTuple = <Args extends string[]>(...args: Args) => args
export const tuple = <Args extends any[]>(...args: Args) => args

export const compactObject = <T extends Record<string, any>>(t: T): T => {
  const result = {} as T
  for (const key in t) {
    if (t[key] !== undefined && t[key] !== null) {
      result[key] = t[key]
    }
  }
  return result
}
