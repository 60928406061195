import dayjs from 'dayjs'
import { Timestamp } from 'firebase/firestore'

export function getToday() {
  return dayjs().toDate()
}

export function getTomorrow() {
  return addDays(getToday(), 1)
}

export function dayDifference(from: Date, to: Date) {
  return dayjs(from).startOf('day').diff(dayjs(to).startOf('day'), 'days')
}

export function addDays(date: Date, days: number) {
  return dayjs(date).add(days, 'days').toDate()
}

export function isSameDay(from: Date, to: Date) {
  return dayDifference(from, to) === 0
}

export type CastGenericDate<T> = {
  [K in keyof T]: T[K] extends GenericDate ? GenericDate : T[K]
}

export type GenericDate = Timestamp | Date

export function toDate(date: GenericDate) {
  return date instanceof Timestamp ? date.toDate() : date
}
