import { isNil, orderBy } from 'lodash'
import { getRoot, Instance, types } from 'mobx-state-tree'
import type { StateInstance } from '.'
import { DBChallenge, DbTeam } from '../lib/Api'
import type { PlayerInstance } from './Player'
import { subscribe } from '../lib/mst-utils'
import { ChallengeModel } from './Challenge'
import { autorun } from 'mobx'

export const TeamModel = types
  .model({
    id: types.identifier,
    raw: types.frozen<DbTeam>(),
    challenge: types.maybe(ChallengeModel),
  })
  .actions((self) => ({
    setRaw(raw: DbTeam) {
      self.raw = raw
    },

    setActiveChallenge(raw: DBChallenge | undefined | null) {
      if (isNil(raw)) {
        self.challenge = undefined
      } else if (!isNil(self.challenge)) {
        self.challenge.setRaw(raw)
      } else {
        self.challenge = ChallengeModel.create({ raw })
      }
    },
  }))
  .views((self) => ({
    get name() {
      return self.raw.name
    },
    get players(): PlayerInstance[] {
      return orderBy(
        getRoot<StateInstance>(self).user?._playersByTeam[self.id] ?? [],
        (d) => d.name + d.surname
      )
    },
  }))

  .actions(
    subscribe((self) =>
      autorun(() => {
        self.setActiveChallenge(self.raw.challenge)
      })
    )
  )

export interface TeamInstance extends Instance<typeof TeamModel> {}
