import { observer } from 'mobx-react-lite'
import { useCoolForm } from '../lib/hooks/useCoolForm'
import { Button } from './ui/Button'
import { Input } from './ui/Input'

interface FormTeamCreate {
  name: string
}

interface FormTeamProps {
  name?: string
  onCreate?: (event: FormTeamCreate) => void
  labelConfirm: string
}

export const FormTeam = observer(
  ({ name = '', onCreate, labelConfirm }: FormTeamProps) => {
    const { register, handleSubmit, errors } = useCoolForm({
      defaultValues: { name },
      errors: (values) => ({
        name: !values.name
          ? { message: 'Aggiungi il nome della squadra' }
          : undefined,
      }),
    })

    const onSubmit = handleSubmit(async (data) => {
      onCreate?.(data)
    })

    return (
      <form onSubmit={onSubmit}>
        <Input
          label="Nome della squadra"
          {...register('name')}
          error={errors.name?.message}
        />
        <Button type="submit">{labelConfirm}</Button>
      </form>
    )
  }
)
