import { autorun } from 'mobx'
import { types } from 'mobx-state-tree'
import Api from '../lib/Api'
import { subscribe } from '../lib/mst-utils'
import { LoadingModel } from './Loading'

function afterImageLoad(src: string) {
  return new Promise<void>((resolve) => {
    const image = new Image()

    image.addEventListener('load', () => resolve())
    image.src = src
  })
}

function proxyFirebaseStorageUrl(url: string | undefined) {
  return url?.replace(
    'https://firebasestorage.googleapis.com',
    '/firebasestorage'
  )
}

export const StorageImage = types
  .model({
    loading: LoadingModel('success'),
    storagePath: types.maybeNull(types.string),
    url: types.maybe(types.string),
  })
  .actions((self) => ({
    setStoragePath(storagePath: string | null) {
      self.storagePath = storagePath
    },
    setUrl(url: string | undefined) {
      self.url = proxyFirebaseStorageUrl(url)
    },
  }))
  .actions(
    subscribe((self) =>
      autorun(async () => {
        const { storagePath } = self

        self.setUrl(undefined)
        if (!storagePath) return

        self.loading.set('pending')
        try {
          const url = await Api.getStorageUrl(storagePath)
          await afterImageLoad(url)

          self.setUrl(url)
          self.loading.set('success')
        } catch (e) {
          console.error(e)
          self.loading.set('error')
        }
      })
    )
  )
