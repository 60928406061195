import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  Query,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore'
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage'
import {
  db,
  signIn,
  signInGoogle,
  signOut,
  storage,
  userId,
  resetPassword,
} from '../firebase'

type Id = string
export type WithId<T> = T & { id: Id }

export const GENDERS = ['Uomo', 'Donna', 'Altro']
export interface DbUserInfo {
  name: string
  surname: string
  imageStoragePath: string | null
  weight?: DBMeasure | null
  gender?: string | null
}

export type DBMeasure = Record<string, number | null>

export interface DBChallenge {
  from: Timestamp
  duration: number
}

export interface DbTeam {
  name: string
  created: Timestamp
  deleted: boolean
  challenge?: DBChallenge | null
  previousChallenges?: DBChallenge[] | null
}

export interface DbPlayer {
  name: string
  surname: string
  team: { from: Timestamp; id: Id } | null
  previousTeams?: { from: Timestamp; id: Id; to: Timestamp }[] | null
  created: Timestamp
  imageStoragePath: string | null
  deleted: boolean
  weight?: DBMeasure | null
  activity?: DBMeasure | null
  gender?: string | null
  mail?: string | null
  phone?: string | null
}

export interface UserData {
  info: DbUserInfo | null
  teams: WithId<DbTeam>[]
  players: WithId<DbPlayer>[]
}

export const Db = {
  user: () => doc(db, 'users', userId()) as DocumentReference<DbUserInfo>,
  teams: () => collection(Db.user(), 'teams') as CollectionReference<DbTeam>,
  team: (id: string) => doc(Db.teams(), id),
  players: () =>
    collection(Db.user(), 'players') as CollectionReference<DbPlayer>,
  player: (id: string) => doc(Db.players(), id),
}

const notDeleted = <T>(q: Query<T>) => query(q, where('deleted', '==', false))

const Op = {
  getDoc: async <T>(ref: DocumentReference<T>) => {
    const doc = await getDoc(ref)
    return doc.data()
  },
  getDocs: async <T>(ref: Query<T>) => {
    const res = await getDocs(ref)
    const data: WithId<T>[] = res.docs.map((d) => ({ id: d.id, ...d.data() }))
    return data
  },
  setDoc: async <T>(ref: DocumentReference<T>, data: T) =>
    await setDoc(ref, data),
  addDoc: async <T>(ref: CollectionReference<T>, data: T): Promise<Id> => {
    const doc = await addDoc(ref, data)
    return doc.id
  },
  deleteDoc: async <T>(ref: DocumentReference<T>) => {
    await deleteDoc(ref)
  },
}

const Api = {
  signIn,
  signInGoogle,
  signOut,
  resetPassword,

  getUserInfo: async () => {
    return await Op.getDoc(Db.user())
  },
  setUserInfo: async (info: DbUserInfo) => {
    return await Op.setDoc(Db.user(), info)
  },
  createTeam: async (team: DbTeam) => {
    return await Op.addDoc(Db.teams(), team)
  },
  setTeam: async (id: Id, team: DbTeam) => {
    return await Op.setDoc(Db.team(id), team)
  },
  getTeams: async () => {
    return await Op.getDocs(notDeleted(Db.teams()))
  },

  createPlayer: async (player: DbPlayer) => {
    return await Op.addDoc(Db.players(), player)
  },

  setPlayer: async (id: Id, player: DbPlayer) => {
    return await Op.setDoc(Db.player(id), player)
  },
  deletePlayer: async (id: Id) => {
    return await Op.deleteDoc(Db.player(id))
  },

  getPlayers: async () => {
    return await Op.getDocs(notDeleted(Db.players()))
  },

  getUserData: async (): Promise<UserData> => {
    const info = (await Api.getUserInfo()) ?? null
    const teams = await Api.getTeams()
    const players = await Api.getPlayers()
    return { info, teams, players }
  },

  uploadUserBlob: async (filename: string, blob: Blob) => {
    const path = `users/${userId()}/${filename}`
    const storageRef = ref(storage, path)
    const result = await uploadBytes(storageRef, blob)
    return result.metadata.fullPath
  },

  deleteUserBlob: async (path: string) => {
    const storageRef = ref(storage, path)
    await deleteObject(storageRef)
  },

  getStorageUrl: async (fullpath: string) => {
    return await getDownloadURL(ref(storage, fullpath))
  },

  uploadUserPic: async (image: Blob) => {
    const filename = `pic-${Date.now()}`
    return Api.uploadUserBlob(filename, image)
  },
}

export default Api

export type ApiResult<T extends (...args: any[]) => Promise<any>> = Awaited<
  ReturnType<T>
>
