import classNames from 'classnames'
import React from 'react'

export const Card = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={classNames(
      'md:border md:p-4 md:shadow rounded-md',
      props.className
    )}
  />
)
