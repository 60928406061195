import { ReactComponent as loading } from '../../svg/loading.svg'
import { ReactComponent as photoAdd } from '../../svg/add_a_photo.svg'
import { ReactComponent as deleteSvg } from '../../svg/delete.svg'
import { ReactComponent as edit } from '../../svg/edit.svg'
import { ReactComponent as person } from '../../svg/person.svg'

export const Icons = {
  loading,
  photoAdd,
  delete: deleteSvg,
  edit,
  person,
}

export type IconType = keyof typeof Icons
