import { observer } from 'mobx-react-lite'
import { ButtonWithDialog } from './ButtonWithDialog'

interface ModalDeleteProps {
  onDelete: () => void
}
export const ButtonDelete = observer(({ onDelete }: ModalDeleteProps) => {
  return (
    <>
      <ButtonWithDialog
        preset="error"
        dialog={{
          onConfirm: onDelete,
          label: 'Sei sicuro di voler elimiare?',
          confirmButton: { label: 'Elimina', preset: 'error' },
          cancelButton: { label: 'Torna indietro' },
        }}
      >
        Elimina
      </ButtonWithDialog>
    </>
  )
})
