import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import Api from '../lib/Api'
import { Button } from './ui/Button'
import { Icons } from './ui/Icons'
import { Title } from './ui/Title'

export const SectionResetUserPasswod = observer(() => {
  const [status, setPasswordMailStatus] = useState<
    undefined | 'pending' | 'sent'
  >()
  return (
    <>
      <Title>Modifica la password</Title>

      <div>
        Per cambiare la password, clicca sul bottone. Ti invieremo una mail,
        segui la procedura e riesegui il login.
      </div>

      <div className="my-4 flex items-center space-x-4">
        <Button
          disabled={status === 'pending' || status === 'sent'}
          onClick={async () => {
            setPasswordMailStatus('pending')
            await Api.resetPassword()
            setPasswordMailStatus('sent')
          }}
        >
          {status === 'sent' ? 'Mail inviata' : 'Invia mail'}
        </Button>

        {status === 'pending' && (
          <Icons.loading
            width={24}
            height={24}
            className="animate-spin duration-1000"
          />
        )}
      </div>
    </>
  )
})
