type Unsubscribe = () => void
type Subscribe<T> = (self: T) => Unsubscribe | void

export const subscribe = <T>(fn: Subscribe<T>) => {
  return (self: T) => {
    let disposer: Unsubscribe | undefined
    return {
      afterCreate() {
        let res = fn(self)
        if (res) disposer = res
      },

      beforeDestroy() {
        disposer?.()
      },
    }
  }
}

export const afterCreate = <T>(fn: (self: T) => void) => {
  return (self: T) => ({
    afterCreate() {
      fn(self)
    },
  })
}
