import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { FormPlayer } from '../components/FormPlayer'
import { Header } from '../components/layout/Header'
import { Container } from '../components/ui/Container'
import { Title } from '../components/ui/Title'
import { PlayerInstance } from '../state/Player'
import { useUser } from '../state'
import { LoadingFallback } from '../components/layout/LoadingFallback'
import { Loader } from '../components/ui/Loader'
import { Path } from '../lib/constants'
import { ButtonDelete } from '../components/ui/ButtonDelete'
import { Page } from '../components/layout/Page'
import { Divider } from '../components/ui/Divider'

export const EditPlayerPage = observer(() => {
  const { playerId } = useParams()
  const user = useUser()!
  const player = user.players.find((p) => p.id === playerId)

  return (
    <LoadingFallback>
      <Page>
        <Header />

        {player ? (
          <EditPlayerPageContent player={player} />
        ) : (
          <Container>Modifica la informazioni del giocatore</Container>
        )}
      </Page>
    </LoadingFallback>
  )
})

interface EditPlayerPageContentProps {
  player: PlayerInstance
}

export const EditPlayerPageContent = observer(
  ({ player }: EditPlayerPageContentProps) => {
    const user = useUser()!
    const navigate = useNavigate()
    if (player.image.loading.pending) return <Loader />
    return (
      <Container>
        <Title>Modifica la informazioni del giocatore</Title>

        <div className="space-y-4">
          <FormPlayer
            name={player.name}
            surname={player.surname}
            image={player.image.url}
            gender={player.raw.gender ?? undefined}
            mail={player.raw.mail ?? undefined}
            phone={player.raw.phone ?? undefined}
            onConfirm={async (data) => {
              await user.fetchUpdatePlayer(player, data)
              navigate(Path.team(player.team?.id!))
            }}
            labelConfirm="Salva"
          />

          <Divider />

          <ButtonDelete
            onDelete={async () => {
              const teamId = player.team!.id
              await user.fetchDeletePlayer(player)
              navigate(Path.team(teamId))
            }}
          />
        </div>
      </Container>
    )
  }
)
