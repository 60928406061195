import { Icons } from './Icons'

export const Loader = () => (
  <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center text-blue-500 z-50 bg-white">
    <Icons.loading
      className="animate-spin duration-1000"
      width={34}
      height={34}
    />
  </div>
)
