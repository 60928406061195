/* eslint-disable react/jsx-pascal-case */
import * as Tabs from '@radix-ui/react-tabs'
import classNames from 'classnames'
import { Dictionary, isNaN, zipObject } from 'lodash'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect, useState } from 'react'
import { formatDay } from '../lib/format-utils'
import { useUser } from '../state'
import { ChallengeInstance } from '../state/Challenge'
import { PlayerInstance } from '../state/Player'
import { TeamInstance } from '../state/Team'
import { UserInstance } from '../state/User'
import { daylyMessage, endMessage, TeamMessage } from './TeamMessage'
import { Button } from './ui/Button'
import { Icons } from './ui/Icons'

function sanitizeNumberInput(value: string) {
  const sanitized = value.replace(',', '.').trim()
  return sanitized !== '' ? sanitized : null
}

function isValidNumberInput(value: string) {
  return value === '' || !isNaN(Number(sanitizeNumberInput(value)))
}

function parseNumberInput(value: string | null) {
  return value !== null ? Number(value) : null
}

type State = Dictionary<Dictionary<string>>

const getPlayerInitialState = (
  days: string[],
  players: PlayerInstance[],
  getValue: (p: PlayerInstance, day: string) => string
): State =>
  zipObject(
    days,
    days.map((day) =>
      zipObject(
        players.map((p) => p.id),
        players.map((player) => getValue(player, day))
      )
    )
  )

const getUserInitialState = (
  days: string[],
  user: UserInstance
): Dictionary<string> => {
  return zipObject(
    days,
    days.map((day) => String(user.weights[day] ?? ''))
  )
}

const hasValidChanges = (state: State, storedState: State) => {
  return Object.entries(state)
    .flatMap(([day, dayObj]) =>
      Object.entries(dayObj).map(([player, value]) => {
        return { value, storedValue: storedState[day][player] }
      })
    )
    .filter(({ value }) => isValidNumberInput(value))
    .some(({ value, storedValue }) => {
      return (
        parseNumberInput(sanitizeNumberInput(value)) !==
        parseNumberInput(sanitizeNumberInput(storedValue))
      )
    })
}

const getApiInput = (state: State) => {
  const array = Object.entries(state).flatMap(([player, dayObj]) =>
    Object.entries(dayObj)
      .map(([day, value]) => ({ day, player, value }))
      .filter(({ value }) => isValidNumberInput(value))
      .map((input) => ({
        ...input,
        value: parseNumberInput(sanitizeNumberInput(input.value)),
      }))
  )

  return array
}

export interface FormMeasuresProps {
  team: TeamInstance
  challenge: ChallengeInstance
}

export const FormMeasures = observer(
  ({ team, challenge }: FormMeasuresProps) => {
    const user = useUser()!
    const { currentDay: daysFromDay1, days, duration } = challenge

    const dayNames = days.map((day) => day.name)

    const userStoredWeights = getUserInitialState(dayNames, user)

    const storedWeights = getPlayerInitialState(
      dayNames,
      team.players,
      (player, day) => String(player.raw.weight?.[day] ?? '')
    )

    const storedActivity = getPlayerInitialState(
      dayNames,
      team.players,
      (player, day) => String(player.raw.activity?.[day] ?? '')
    )

    const [weights, setWeights] = useState<State>(() => storedWeights)
    const [activity, setActivity] = useState<State>(() => storedActivity)
    const [userWeights, setUserWeights] = useState(() => userStoredWeights)

    const hasChanges =
      hasValidChanges(weights, storedWeights) ||
      hasValidChanges(activity, storedActivity) ||
      hasValidChanges({ user: userWeights }, { user: userStoredWeights })

    useEffect(() => {
      if (user.formMeasuresLoading.pending) return
      if (!hasChanges) return

      let cancel = false

      setTimeout(async () => {
        if (cancel) return
        user.fetchUpdateTeamMeasures(
          getApiInput({ userWeights }),
          getApiInput(weights),
          getApiInput(activity)
        )
      }, 500)

      return () => {
        cancel = true
      }
    }, [
      userWeights,
      weights,
      activity,
      hasChanges,
      user.formMeasuresLoading.pending,
      user,
    ])

    return (
      <div>
        <div className="flex justify-end items-center space-x-3">
          {user.formMeasuresLoading.pending ? (
            <>
              <div className="text-gray-500 italic">Salvataggio</div>
              <Icons.loading
                className="animate-spin duration-1000"
                width={16}
                height={16}
              />
            </>
          ) : !hasChanges ? (
            <div className="text-gray-500 italic">Nessun cambiamento</div>
          ) : null}
          <Button
            disabled={!hasChanges || user.formMeasuresLoading.pending}
            onClick={async () =>
              await user.fetchUpdateTeamMeasures(
                getApiInput({ userWeights }),
                getApiInput(weights),
                getApiInput(activity)
              )
            }
          >
            Salva
          </Button>
        </div>

        <Tabs.Root
          className="TabsRoot"
          defaultValue={days.find((d) => d.number === daysFromDay1)?.name}
        >
          <Tabs.List
            className="overflow-x-auto max-w-full w-full flex-nowrap flex sticky top-[70px] bg-white"
            aria-label="Scegli il giorno da modificare"
          >
            {days.map((day) => (
              <Tabs.Trigger
                key={day.name}
                className=" border hover:bg-gray-100 radix-active:bg-gray-200 radix-active:border-black rounded-t whitespace-nowrap w-full min-w-[100px] relative"
                value={String(day.name)}
              >
                <div className="m-2">
                  <div>{day.number}</div>
                  <div>{formatDay(day.date)}</div>
                </div>
              </Tabs.Trigger>
            ))}
          </Tabs.List>

          {days.map((day, i) => (
            <Tabs.Content value={day.name} key={day.name}>
              <>
                {day.name}

                <div className="hidden md:flex">
                  <div className="w-full md:max-w-[150px]"></div>
                  <div className="font-bold w-full">Peso</div>
                  <div className="font-bold w-full">Attività</div>
                </div>

                <Form title={`${user.name} ${user.surname}`}>
                  <FormMeasuresInput
                    label="Peso"
                    value={userWeights[day.name]}
                    storedValue={userStoredWeights[day.name]}
                    onChange={(value) => {
                      setUserWeights((state) => {
                        const newState = { ...state }
                        newState[day.name] = value
                        return newState
                      })
                    }}
                  />

                  <div className="w-full" />
                </Form>
                {team.players
                  .map((p) => ({ name: p.name, surname: p.surname, id: p.id }))
                  .map((p, i) => (
                    <Form key={i} title={`${p.name} ${p.surname}`}>
                      <FormMeasuresInput
                        label="Peso"
                        value={weights[day.name][p.id]}
                        storedValue={storedWeights[day.name][p.id]}
                        onChange={(value) => {
                          setWeights((state) => {
                            const newState = { ...state }
                            newState[day.name][p.id] = value
                            return newState
                          })
                        }}
                      />

                      <FormMeasuresInput
                        label="Attività"
                        value={activity[day.name][p.id]}
                        storedValue={storedActivity[day.name][p.id]}
                        onChange={(value) => {
                          setActivity((state) => {
                            const newState = { ...state }
                            newState[day.name][p.id] = value
                            return newState
                          })
                        }}
                      />
                    </Form>
                  ))}
              </>
              {day.number === duration ? (
                <TeamMessage>
                  {endMessage(user, team, challenge, day.name)}
                </TeamMessage>
              ) : (
                <TeamMessage>
                  {daylyMessage(user, team, challenge, day.name)}
                </TeamMessage>
              )}
            </Tabs.Content>
          ))}
          <Tabs.Content className="TabsContent" value="tab1"></Tabs.Content>
        </Tabs.Root>
      </div>
    )
  }
)

interface FormMeasuresInputProps {
  label: string
  value: string
  storedValue: string
  onChange: (value: string) => void
}

const FormMeasuresInput = observer(
  ({ value, storedValue, onChange, label }: FormMeasuresInputProps) => {
    return (
      <div className="flex items-center gap-2 w-full">
        <div className="w-28 md:hidden">{label}</div>
        <input
          className={classNames(
            'border my-1 p-1 rounded w-full placeholder:italic',
            !isValidNumberInput(value)
              ? 'border-red-500 outline-red-500'
              : parseNumberInput(sanitizeNumberInput(value)) !==
                parseNumberInput(sanitizeNumberInput(storedValue))
              ? 'border-blue-500 outline-blue-500'
              : ''
          )}
          value={value}
          onChange={(e) => {
            const value = e.target.value

            onChange(value)
          }}
        />
      </div>
    )
  }
)

const Form = ({ title, children }: { title: string; children?: ReactNode }) => {
  return (
    <div className="mb-3 md:mb-0 md:flex items-center">
      <div className="w-full md:max-w-[150px]">
        <strong>{title}</strong>
      </div>

      {children}
    </div>
  )
}
