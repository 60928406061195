import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { GENDERS } from '../lib/Api'
import { useCoolForm } from '../lib/hooks/useCoolForm'
import { Button } from './ui/Button'
import { ImagePicker } from './ui/ImagePicker'
import { Input } from './ui/Input'
import { Select } from './ui/Select'

interface FormPlayerCreate {
  name: string
  surname: string
  image?: Blob | string
  gender: string
  phone?: string
  mail?: string
}

interface FormPlayerProps {
  name?: string
  surname?: string
  image?: string
  gender?: string
  mail?: string
  phone?: string
  onConfirm?: (data: FormPlayerCreate) => void
  onCancel?: () => void
  labelConfirm: string
}

export const FormPlayer = observer(
  ({
    onConfirm,
    onCancel,
    name: initialName = '',
    surname: initialSurname = '',
    image: initialImage,
    gender: initialGender = '',
    mail: initialMail = '',
    phone: initialPhone = '',
    labelConfirm,
  }: FormPlayerProps) => {
    const [image, setImage] = useState<Blob | string | undefined>(initialImage)
    const { register, handleSubmit, errors } = useCoolForm({
      defaultValues: {
        gender: initialGender,
        name: initialName,
        surname: initialSurname,
        mail: initialMail,
        phone: initialPhone,
      },
      errors: (values) => ({
        gender: !values.gender ? { message: 'Scegli Uomo o Donna' } : undefined,
        name: !values.name ? { message: 'Aggiungi il nome' } : undefined,
        surname: !values.surname
          ? { message: 'Aggiungi il cognome' }
          : undefined,
        mail: undefined,
        phone: undefined,
      }),
    })

    const onSubmit = handleSubmit(async (data) => {
      onConfirm?.({
        name: data.name,
        surname: data.surname,
        image: image,
        gender: data.gender,
        mail: data.mail || undefined,
        phone: data.phone || undefined,
      })
    })

    return (
      <form onSubmit={onSubmit} className="">
        <div className="flex md:space-x-5 space-y-5 space-y-reverse flex-col-reverse md:flex-row">
          <div className="md:w-2/3">
            <Input
              label="Nome"
              {...register('name')}
              error={errors.name?.message}
            />
            <Input
              label="Cognome"
              {...register('surname')}
              error={errors.surname?.message}
            />

            <Select
              {...register('gender')}
              label="Uomo/Donna"
              error={errors.gender?.message}
            >
              {GENDERS.map((g) => (
                <option key={g} value={g}>
                  {g}
                </option>
              ))}
            </Select>

            <Input label="Email" type={'email'} {...register('mail')} />

            <Input label="Telefono" type="tel" {...register('phone')} />
          </div>

          <ImagePicker
            image={initialImage}
            onCrop={(blob) => setImage(blob)}
            onClear={() => setImage(undefined)}
          />
        </div>

        <div className="space-x-4">
          <Button type="submit">{labelConfirm}</Button>
          {onCancel && <Button onClick={onCancel}>Annulla</Button>}
        </div>
      </form>
    )
  }
)
