import { observer } from 'mobx-react-lite'
import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route,
  Navigate,
} from 'react-router-dom'
import { Path } from '../lib/constants'
import { useUser } from '../state'
import { CreateTeamPage } from './CreateTeamPage'
import { EditChallengePage } from './EditChallengePage'
import { EditPasswordPage } from './EditPasswordPage'
import { EditPlayerPage } from './EditPlayerPage'
import { EditTeamPage } from './EditTeamPage'
import { EditUserPage } from './EditUserPage'
import { LoginPage } from './LoginPage'
import { StartChallengePage } from './StartChallengePage'
import { TeamPage } from './TeamPage'
import { UserPage } from './UserPage'

export const Routes = observer(() => {
  const user = useUser()
  return (
    <BrowserRouter>
      <BrowserRoutes>
        {user ? (
          <>
            <Route path={Path.user} element={<UserPage />} />
            <Route path={Path.editUser} element={<EditUserPage />} />
            <Route path={Path.team(':teamId')} element={<TeamPage />} />
            <Route path={Path.createTeam} element={<CreateTeamPage />} />
            <Route path={Path.editTeam(':teamId')} element={<EditTeamPage />} />
            <Route
              path={Path.editPlayer(':playerId')}
              element={<EditPlayerPage />}
            />
            <Route
              path={Path.startChallenge(':teamId')}
              element={<StartChallengePage />}
            />
            <Route
              path={Path.editChallenge(':teamId')}
              element={<EditChallengePage />}
            />
            <Route path="*" element={<Navigate to={Path.user} />} />
          </>
        ) : (
          <>
            <Route path={Path.login} element={<LoginPage />} />
            <Route path={Path.editPassword} element={<EditPasswordPage />} />
            <Route path="*" element={<Navigate to={Path.login} />} />
          </>
        )}
      </BrowserRoutes>
    </BrowserRouter>
  )
})
