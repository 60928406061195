import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ChallengeSection } from '../components/ChallengeSection'
import { FormMeasures } from '../components/FormMeasures'
import { FormPlayer } from '../components/FormPlayer'
import { Header } from '../components/layout/Header'
import { Page } from '../components/layout/Page'
import { TeamImage } from '../components/TeamImage'
import { Button } from '../components/ui/Button'
import { Card } from '../components/ui/Card'
import { Container } from '../components/ui/Container'
import { Divider } from '../components/ui/Divider'
import { Title } from '../components/ui/Title'
import { Path, USER_PROFILE_IMG_URL } from '../lib/constants'
import { useUser } from '../state'
import { TeamInstance } from '../state/Team'

export const TeamPage = observer(() => {
  const user = useUser()!
  const { teamId } = useParams()
  const team = user.teams.find((t) => t.id === teamId)

  return (
    <Page>
      <Header />
      {team ? (
        <TeamPageContent team={team} />
      ) : (
        <Container>oops, la squadra non esiste</Container>
      )}
    </Page>
  )
})

const TeamPageContent = observer(({ team }: { team: TeamInstance }) => {
  const user = useUser()!

  const [isCreating, setCreating] = useState(false)
  return (
    <Container>
      <Title>Squadra: {team.name}</Title>

      <div className="flex justify-end">
        <Link to={Path.editTeam(team.id)}>
          <Button>Modifica Squadra</Button>
        </Link>
      </div>

      <Divider />

      <Title preset="secondary">Maratona</Title>
      <ChallengeSection team={team} />

      {team.challenge && (
        <FormMeasures team={team} challenge={team.challenge} />
      )}
      <Divider />

      <Title preset="secondary">Giocatori: </Title>

      {team.players.length < 8 && (
        <div className="my-4">
          {!isCreating ? (
            <Button onClick={() => setCreating(true)}>
              Aggiungi giocatore
            </Button>
          ) : (
            <Card>
              <FormPlayer
                onConfirm={async (args) => {
                  await user.fetchCreatePlayer({
                    ...args,
                    image: args.image instanceof Blob ? args.image : undefined,
                    teamId: team.id,
                  })
                  setCreating(false)
                }}
                onCancel={() => setCreating(false)}
                labelConfirm="Aggiungi"
              />
            </Card>
          )}
        </div>
      )}

      {team.players.map((d) => (
        <div id={d.id} key={d.id} className="flex border">
          <img
            src={d.image.url ?? USER_PROFILE_IMG_URL}
            className="w-20 h-20"
            alt=""
          />
          <div className="p-5 flex flex-auto justify-between">
            <div>
              {d.name} {d.surname}
            </div>
            <div>
              <Link to={Path.editPlayer(d.id)}>
                <Button icon="edit">Modifica</Button>
              </Link>
            </div>
          </div>
        </div>
      ))}

      <Card className="my-4">
        <TeamImage team={team} />
      </Card>
    </Container>
  )
})
