import dayjs from 'dayjs'
import { clamp } from 'lodash'
import { DBChallenge } from '../Api'
import {
  CastGenericDate,
  dayDifference,
  getToday,
  toDate,
} from '../dates-utils'

export function isChallengeActive(challenge: CastGenericDate<DBChallenge>) {
  const { duration } = challenge
  const startDate = toDate(challenge.from)

  const today = getToday()

  const daysFromStart = dayDifference(today, startDate)
  return daysFromStart >= 0 && daysFromStart < duration
}

export function isChallengeOver(challenge: DBChallenge) {
  const { duration } = challenge
  const startDate = challenge.from.toDate()
  const today = getToday()

  const daysFromStart = dayDifference(today, startDate)
  return daysFromStart >= duration - 1
}

export function getChallengeFirstDay(challenge: DBChallenge) {
  return challenge.from.toDate()
}

export function getChallengeLastDay(challenge: DBChallenge) {
  return dayjs(challenge.from.toDate())
    .add(challenge.duration - 1, 'day')
    .toDate()
}
export function getChallengeCurrentDay(challenge: DBChallenge) {
  const startDate = challenge.from.toDate()
  const today = getToday()

  return clamp(dayDifference(today, startDate) + 1, 0, challenge.duration)
}

export function isChallengeLastDay(challenge: DBChallenge) {
  const { duration } = challenge
  const startDate = challenge.from.toDate()
  const today = getToday()

  return dayDifference(today, startDate) === duration - 1
}
