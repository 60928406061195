import { compact, orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { PlayerScore } from '../lib/data/measures'
import { formatActivity, formatWeightDelta } from '../lib/format-utils'
import { ChallengeInstance } from '../state/Challenge'
import { TeamInstance } from '../state/Team'
import { UserInstance } from '../state/User'
import { Button } from './ui/Button'
import { TextAreaReadonly } from './ui/TextAreaReadonly'

export interface TeamMessageProps {
  children: string
}

export function daylyMessage(
  user: UserInstance,
  team: TeamInstance,
  challenge: ChallengeInstance,
  day: string
) {
  const row = (score: PlayerScore, hideActivity = false) =>
    compact([
      formatWeightDelta(score.weightDeltaFromStart),
      formatActivity(score.weightPointsFromStart),
      !hideActivity && formatActivity(score.activityCumulative),
    ]).join('      ')
  return `${team.name}
    
💥 Complimenti per essere "in gioco"💥
✅ Vi ricordo che i punti vengono così calcolati: 
- ogni etto perso vale 1 punto🥇
- ogni attività che vi proponiamo ha il suo punteggio
  
Ecco i risultati fino ad oggi:
  
✅ Totale kg persi dal gruppo:     ${
    formatWeightDelta(challenge.scoresTeam[day].weightDeltaFromStart) || 0
  }
✅ Totale punti attività accumulati dal gruppo:     ${
    formatActivity(challenge.scoresTeam[day].activityCumulative) || 0
  }
  
✅ Risultati INDIVIDUALI:
peso - punti peso - punti attività

${user.name}\n${row(challenge.scoresUser[day], true)}
${team.players
  .map((p) => `${p.name}\n${row(challenge.scoresByPlayers[p.id][day])}`)
  .join('\n')}

Vi ricordo che posso aggiornare soltanto chi mi manda il proprio peso in privato!
AVANTI TUTTA 💪💪💪`
}

export function endMessage(
  user: UserInstance,
  team: TeamInstance,
  challenge: ChallengeInstance,
  day: string
) {
  // const row = (score: PlayerScore) =>
  //   [
  //     formatActivity(score.weightPointsFromStart),
  //     '+',
  //     formatActivity(score.activityCumulative),
  //     '=',
  //     formatActivity(score.totalPointsFromStart),
  //   ].join('  ')
  return `${team.name}
    
💥 Complimenti!💥 Oggi si conclude questo girone di maratona ${
    challenge.duration
  } giorni.
Ecco i risultati ottenuti:
  
✅ Totale punteggio complessivo della squadra:     ${
    formatActivity(challenge.scoresTeam[day].totalPointsFromStart) || 0
  }
  
✅ Risultati INDIVIDUALI:
punti totale  

${orderBy(
  [
    { name: user.name, score: challenge.scoresUser[day], capitano: true },
    ...team.players.map((p) => ({
      name: p.name,
      score: challenge.scoresByPlayers[p.id][day],
      capitano: false,
    })),
  ],
  (d) => d.score.totalPointsFromStart,
  'desc'
)
  .map(
    (p) =>
      p.name +
      '\n' +
      // (p.capitano ? formatActivity(p.score.totalPointsFromStart) : row(p.score))
      formatActivity(p.score.totalPointsFromStart)
  )
  .join('\n')}
  
Complimenti al TOP PERFORMER del gruppo ${challenge.topPerformersByDay[
    day
  ].players
    .map((p) => p.name)
    .join(', ')} con ${formatActivity(
    challenge.topPerformersByDay[day].score
  )} punti`
}

export const TeamMessage = observer(({ children }: TeamMessageProps) => {
  return (
    <div className="space-y-4 border p-2  rounded-lg shadow-md">
      <TextAreaReadonly value={children} />
      <Button onClick={() => navigator.clipboard.writeText(children)}>
        Copia
      </Button>
    </div>
  )
})
