import { FirebaseUser } from '../firebase'

export const VolatileFirebaseUser = () => {
  let firebaseUser: FirebaseUser | null = null

  return {
    views: {
      get firebaseUser() {
        if (!firebaseUser)
          throw new Error(
            'Please assign firebaseUser right after model creation'
          )
        return firebaseUser!
      },
    },
    actions: {
      __setFirebaseUser(user: FirebaseUser) {
        if (firebaseUser) throw new Error('Cannot assign firebaseUser twice')
        firebaseUser = user
      },
    },
  }
}
