import classNames from 'classnames'
import { forwardRef } from 'react'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string
  inline?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, label, inline = false, ...props }, ref) => {
    return (
      <div className={classNames(inline && 'md:flex items-center gap-4')}>
        <label
          className={classNames('block', inline && 'w-full md:max-w-[20%]')}
          htmlFor={props.name}
        >
          {label}
        </label>
        <input
          {...props}
          ref={ref}
          className={classNames(
            props.className,
            'border my-1 p-1 rounded w-full placeholder:italic'
          )}
        />
        <div className="text-red-400 h-6 text-sm">{error}</div>
      </div>
    )
  }
)
