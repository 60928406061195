import { types as t } from 'mobx-state-tree'
import { strTuple } from '../lib/types-utils'

const loadingOptions = strTuple('pending', 'error', 'success', 'retry')
export type LoadingOption = typeof loadingOptions[number]

export const LoadingModel = (init: LoadingOption = loadingOptions[0]) =>
  t.optional(
    t
      .model('LoadingStatus', {
        status: t.optional(t.enumeration<LoadingOption>(loadingOptions), init),
      })
      .actions((self) => ({
        set(status: LoadingOption) {
          self.status = status
        },
      }))
      .views((self) =>
        loadingOptions.reduce(
          (getters, option) =>
            Object.defineProperty(getters, option, {
              configurable: true,
              enumerable: true,
              get() {
                return self.status === option
              },
            }),
          {} as { [k in LoadingOption]: boolean }
        )
      ),
    {}
  )
