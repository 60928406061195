import { Instance, types } from 'mobx-state-tree'
import { auth, FirebaseUser } from '../firebase'
import { subscribe } from '../lib/mst-utils'
import { createSafeContext } from '../lib/react-utils'
import { UserModel } from './User'

export const StateModel = types
  .model('StateModel', {
    user: types.maybeNull(UserModel),
    isUserChecked: false,
  })
  .actions((self) => ({
    setUser(user: FirebaseUser | null) {
      if (user) {
        self.user = UserModel.create()
        self.user.__setFirebaseUser(user)
      } else {
        self.user = null
      }
    },

    setIsUserChecked() {
      self.isUserChecked = true
    },

    // goto(path: string, force = false) {
    //   if (force || self.route.location.pathname !== path) {
    //     self.route.push(path)
    //   }
    // },
  }))
  .actions(
    subscribe((self) =>
      auth.onAuthStateChanged((user) => {
        self.setUser(user)
        self.setIsUserChecked()
      })
    )
  )
export interface StateInstance extends Instance<typeof StateModel> {}

export const globalState = StateModel.create()
if (process.env.NODE_ENV === 'development') (window as any).STATE = globalState

const [Provider, createHook] = createSafeContext<StateInstance>('StateProvider')
export const StateProvider = Provider
export const useMst = createHook((state) => state)
export const useUser = createHook((state) => state.user)
