export const Path = {
  login: '/login',
  editPassword: '/cambia-password',
  team: (id: string) => `/squadra/${id}`,
  createTeam: '/crea-squadra',
  user: '/',
  editPlayer: (id: string) => `/modifica-giocatore/${id}`,
  editTeam: (id: string) => `/modifica-squadra/${id}`,
  editUser: '/capitano',
  startChallenge: (id: string) => `/inizia-maratona/${id}`,
  editChallenge: (id: string) => `/modifica-maratona/${id}`,
}

export const USER_PROFILE_IMG_URL = '/img/profile.webp'

export const CHALLENGE_DEFAULT_DURATION = 10
export const TEAM_MAX_PLAYERS_LENGTH = 8
