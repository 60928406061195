import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import Api from '../../lib/Api'
import { Path } from '../../lib/constants'
import { useUser } from '../../state'
import { Button } from '../ui/Button'
import { Icons } from '../ui/Icons'

export const Header = observer(() => {
  const user = useUser()!
  return (
    <div className="border-b-2 h-16 shadow-sm flex justify-between items-center px-3 bg-white sticky top-0 z-10">
      <Link to={Path.user}>
        <Button>Squadre</Button>
      </Link>
      <div className="flex space-x-4 items-center">
        <div>Ciao {user.name}</div>
        <div className="w-8 h-8 rounded-full flex justify-center items-center bg-slate-300">
          {user.image.url ? (
            <img src={user.image.url} className="w-8 h-8 rounded-full" alt="" />
          ) : (
            <Icons.person width={24} height={24} fill="white" />
          )}
        </div>
        <Button onClick={Api.signOut}>Logout</Button>
      </div>
    </div>
  )
})
