import classNames from 'classnames'
import { forwardRef } from 'react'
import { Icons, IconType } from './Icons'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconType
  preset?: 'primary' | 'error'
  label?: React.ReactNode
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ icon, children, preset = 'primary', label, ...props }, ref) => {
    const Icon = icon ? Icons[icon] : null
    return (
      <button
        {...props}
        ref={ref}
        className={classNames(
          props.className,
          'border rounded px-2 py-1 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none',
          { 'bg-red-500 text-white hover:bg-red-600': preset === 'error' }
        )}
      >
        <div className="flex space-x-2">
          {Icon && <Icon width={24} height={24} />}
          <div>{label ?? children}</div>
        </div>
      </button>
    )
  }
)
