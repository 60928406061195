import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { Header } from '../components/layout/Header'
import { Container } from '../components/ui/Container'
import { Title } from '../components/ui/Title'
import { useUser } from '../state'
import { LoadingFallback } from '../components/layout/LoadingFallback'
import { Path } from '../lib/constants'
import { Page } from '../components/layout/Page'
import { TeamInstance } from '../state/Team'
import { FormChallenge } from '../components/FormChallenge'

export const StartChallengePage = observer(() => {
  const { teamId } = useParams()
  const user = useUser()!
  const team = user.teams.find((p) => p.id === teamId)

  return (
    <LoadingFallback>
      <Page>
        <Header />

        {team ? (
          <StartChallengeContent team={team} />
        ) : (
          <Container>Team non trovato</Container>
        )}
      </Page>
    </LoadingFallback>
  )
})

interface StartChallengeContentProps {
  team: TeamInstance
}

const StartChallengeContent = observer(
  ({ team }: StartChallengeContentProps) => {
    const user = useUser()!
    const navigate = useNavigate()
    return (
      <Container>
        <Title>Inizia la challenge del team: {team.name}</Title>

        <div className="space-y-4">
          <FormChallenge
            labelConfirm="Inizia"
            onConfirm={async (data) => {
              await user.fetchStartTeamChallenge(team, data)
              navigate(Path.team(team.id))
            }}
          />
        </div>
      </Container>
    )
  }
)
