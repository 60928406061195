export const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms))
export const tick = () => sleep(0)

export const success = (promise: Promise<any>): Promise<boolean> => {
  return new Promise((resolve) =>
    promise.then(() => resolve(true)).catch(() => resolve(false))
  )
}

export const ignoreFail = <T>(promise: Promise<T>): Promise<T | undefined> =>
  new Promise<T | undefined>((resolve) =>
    promise.then(resolve).catch(() => resolve(undefined))
  )
