import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { CHALLENGE_DEFAULT_DURATION } from '../lib/constants'
import { addDays, getTomorrow } from '../lib/dates-utils'
import { useCoolForm } from '../lib/hooks/useCoolForm'
import { Button } from './ui/Button'
import { Input } from './ui/Input'

interface FormChallengeCreate {
  duration: number
  from: Date
}

interface FormChallengeProps {
  duration?: number
  from?: Date
  onConfirm?: (event: FormChallengeCreate) => void
  labelConfirm: string
}

export const FormChallenge = observer(
  ({
    duration: initialDuration = CHALLENGE_DEFAULT_DURATION,
    from: initialFrom = getTomorrow(),
    onConfirm,
    labelConfirm,
  }: FormChallengeProps) => {
    const initialFromString = addDays(initialFrom, -1)
      .toISOString()
      .split('T')[0]

    const { register, handleSubmit, errors } = useCoolForm({
      defaultValues: { duration: initialDuration, from: initialFromString },
      errors: (values) => ({
        duration: !values.duration
          ? { message: 'Aggiungi la durata della challenge' }
          : values.duration < 1
          ? { message: 'La durata deve essere maggiore di 0' }
          : undefined,
        from: !values.from
          ? { message: 'Aggiungi la data di inizio' }
          : undefined,
      }),
    })

    const onSubmit = handleSubmit(async (unsafeData) => {
      const data: FormChallengeCreate = {
        ...unsafeData,
        from: addDays(new Date(unsafeData.from), 1),
      }

      onConfirm?.(data)
    })
    const ref = useRef<HTMLButtonElement>(null)
    return (
      <form onSubmit={onSubmit}>
        <Input
          label="Durata della challenge (in giorni)"
          {...register('duration')}
          error={errors.duration?.message}
        />

        <Input
          type="date"
          label="Giorno 0"
          {...register('from')}
          error={errors.from?.message}
        />

        <Button ref={ref} type="submit">
          {labelConfirm}
        </Button>
      </form>
    )
  }
)
