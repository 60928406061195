import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { FormTeam } from '../components/FormTeam'
import { Header } from '../components/layout/Header'
import { Container } from '../components/ui/Container'
import { Page } from '../components/layout/Page'
import { Title } from '../components/ui/Title'
import { Path } from '../lib/constants'
import { useUser } from '../state'

export const CreateTeamPage = observer(() => {
  const user = useUser()!
  const navigate = useNavigate()
  return (
    <Page>
      <Header />

      <Container>
        <Title>Crea la squadra</Title>

        <FormTeam
          labelConfirm="Crea"
          onCreate={async (data) => {
            const id = await user.fetchCreateTeam(data)
            navigate(Path.team(id!))
          }}
        />
      </Container>
    </Page>
  )
})
