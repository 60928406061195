import { noop } from 'lodash'
import { useEffect, useRef } from 'react'

export interface TextAreaReadonlyProps {
  value: string
}

export const TextAreaReadonly = ({ value }: TextAreaReadonlyProps) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  const updateHeight = () => {
    const el = ref.current
    if (el) {
      el.style.height = ''
      el.style.height = el.scrollHeight + 'px'
    }
  }
  useEffect(updateHeight, [])

  return (
    <textarea
      ref={ref}
      value={value}
      className="w-full outline-none resize-none bg-transparent"
      onChange={noop}
      onInput={updateHeight}
    />
  )
}
