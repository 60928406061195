import { useEffect } from 'react'
import { Portal } from './Portal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

interface ModalProps {
  children?: React.ReactNode
}

export function Modal({ children }: ModalProps) {
  useEffect(() => {
    disableBodyScroll(document.body)
    return () => enableBodyScroll(document.body)
  }, [])
  return (
    <Portal container={document.body}>
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-gray-700 bg-opacity-70">
        {children}
      </div>
    </Portal>
  )
}
