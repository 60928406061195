import { useState } from 'react'
import { success } from '../lib/async-utils'
import { Input } from '../components/ui/Input'
import { Button } from '../components/ui/Button'
import Api from '../lib/Api'
import { useCoolForm } from '../lib/hooks/useCoolForm'
import { Link } from 'react-router-dom'
import { Path } from '../lib/constants'
import { Icons } from '../components/ui/Icons'
import { TextLink } from '../components/ui/LinkText'

type FormValues = {
  email: string
}

export const EditPasswordPage = () => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<'invalid' | 'mail-sent' | undefined>(
    undefined
  )

  const { register, handleSubmit, errors } = useCoolForm<FormValues>({
    errors: (values) => ({
      email: !values.email
        ? {
            type: 'required',
            message: 'Inserisci la mail',
          }
        : undefined,
    }),
  })

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    const resolved = await success(Api.resetPassword(data.email))
    setLoading(false)
    setStatus(resolved ? 'mail-sent' : 'invalid')
  })

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center max-w-lg m-auto space-y-4">
      <form
        className="md:border p-4 md:shadow w-full space-y-4"
        onSubmit={onSubmit}
      >
        <div className="text-center font-bold text-2xl">Cambia password</div>

        {status === 'mail-sent' ? (
          <div className="space-y-4">
            <div>
              Ti abbiamo inviato una mail. Segui i passaggi per cambiare la
              password.
            </div>

            <div>
              <TextLink to={Path.login}>Torna alla pagina di login</TextLink>
            </div>
          </div>
        ) : (
          <>
            <Input
              {...register('email')}
              label="Email"
              type="email"
              placeholder="email"
              error={errors.email?.message}
            />
            {status === 'invalid' && (
              <p className="text-red-500">La mail è errata</p>
            )}

            <div className="flex justify-between">
              <div className="flex space-x-3 items-center">
                <Button type="submit" disabled={loading}>
                  Cambia password
                </Button>
                {loading && (
                  <Icons.loading
                    className="animate-spin duration-1000"
                    width={24}
                    height={24}
                  />
                )}
              </div>
              <div>
                <Link to={Path.login}>
                  <Button>Indietro</Button>
                </Link>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  )
}
