import classNames from 'classnames'
import { forwardRef } from 'react'

export interface SelectProps
  extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string
  error?: string
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, error, ...props }, ref) => {
    return (
      <div>
        <label className="block">{label}</label>
        <select
          ref={ref}
          {...props}
          className={classNames(props.className, 'border p-1 my-1')}
        />

        <div className="text-red-400 h-6 text-sm">{error}</div>
      </div>
    )
  }
)
