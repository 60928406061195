import classNames from 'classnames'
import { orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Header } from '../components/layout/Header'
import { Page } from '../components/layout/Page'
import { Button } from '../components/ui/Button'
import { Container } from '../components/ui/Container'
import { Title } from '../components/ui/Title'
import { Path } from '../lib/constants'
import { generateAllTeamsReport } from '../lib/data/report'
import { downloadExcel, renameKey } from '../lib/excel-utils'
import { formatDate } from '../lib/format-utils'
import { useUser } from '../state'

export const UserPage = observer(() => {
  const user = useUser()!
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.raw) navigate(Path.editUser)
  })

  return (
    <Page>
      <Header />

      <Container>
        <div className="flex justify-end">
          <Link to={Path.editUser}>
            <Button>Modifica le mie informazioni</Button>
          </Link>
        </div>

        <Button
          onClick={() =>
            downloadExcel(
              `Report - ${user.name + ' ' + user.surname}`,
              renameKey(generateAllTeamsReport(user, user.teams), {
                userName: 'Capitano',
                teamName: 'Squadra',
                playerName: 'Nominativo',
                gender: 'Uomo/Donna',
                weightDeltaFromStart: 'Kg Persi',
                totalPointsFromStart: 'Punteggio Finale',
                fromDate: "Data d'inizio",
                toDate: 'Data di fine',
              })
            )
          }
        >
          Genera Report delle maratone attive
        </Button>
        <Title>Le tue squadre:</Title>
        <div>
          {user.teams.length === 0 ? 'Non hai ancora nessuna squadra' : ''}
        </div>

        <Link to={Path.createTeam}>
          <Button>Aggiungi una squadra</Button>
        </Link>

        {orderBy(
          user.teams,
          [(d) => (d.challenge ? 0 : 1), (d) => d.name.toLowerCase()],
          ['asc', 'asc']
        ).map((team) => (
          <Link key={team.id} to={Path.team(team.id)}>
            <div className="border rounded-none px-4 py-4 w-full text-left flex justify-between items-center hover:bg-gray-100">
              <div className="flex space-x-2 align-middle">
                <div>{team.name}</div>

                {team.challenge && (
                  <>
                    <div>&#x2022;</div>
                    <div className="text-sm flex items-center italic text-gray-600">
                      {team.challenge.isLastDay
                        ? 'Ultimo giorno'
                        : team.challenge.isOver
                        ? 'Terminato'
                        : team.challenge.isActive
                        ? `Giorno ${team.challenge.currentDay + 1}/${
                            team.challenge.duration
                          }`
                        : `Inizierà ${formatDate(team.challenge.firstDay)}`}
                    </div>
                  </>
                )}
              </div>
              <div>
                <div className="flex flex-row-reverse -space-x-2 space-x-reverse">
                  {team.players.map((p, i) => (
                    <img
                      key={p.id}
                      src={p.image.url}
                      alt=""
                      className={classNames(
                        'w-7 h-7 bg-slate-300 rounded-full border-2 border-white'
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </Container>
    </Page>
  )
})
