import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { FormUser } from '../components/FormUser'
import { Header } from '../components/layout/Header'
import { Page } from '../components/layout/Page'
import { SectionResetUserPasswod } from '../components/SectionResetUserPasswod'
import { Container } from '../components/ui/Container'
import { Divider } from '../components/ui/Divider'
import { Loader } from '../components/ui/Loader'
import { Title } from '../components/ui/Title'
import { Path } from '../lib/constants'
import { useUser } from '../state'

export const EditUserPage = observer(() => {
  const user = useUser()
  const navigate = useNavigate()
  if (user?.image.loading.pending) return <Loader />

  return (
    <Page>
      <Header />

      <Container>
        <Title>Infomazioni del capitano</Title>

        <FormUser
          name={user?.name}
          surname={user?.surname}
          image={user?.image.url}
          gender={user?.raw?.gender ?? undefined}
          onSubmit={async (data) => {
            await user?.fetchUpdateUser(data)
            navigate(Path.user)
          }}
        />

        <Divider />

        <SectionResetUserPasswod />
      </Container>
    </Page>
  )
})
