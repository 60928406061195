import * as XLSX from 'xlsx'

export function renameKey<T>(data: T[], columns: Record<keyof T, string>) {
  const mappedData = data.map((d) => {
    const row: Record<string, any> = {}
    Object.entries<string>(columns).forEach(([key, value]) => {
      row[value] = d[key as keyof T]
    })
    return row
  })

  return mappedData
}

export function downloadExcel(title: string, row: object[]) {
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(row)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' })
  XLSX.writeFile(workbook, `${title}.xlsx`, { compression: true })
}
