import { last, round } from 'lodash'
import { ChallengeInstance } from '../../state/Challenge'
import { TeamInstance } from '../../state/Team'
import { UserInstance } from '../../state/User'
import { formatDateShort } from '../format-utils'
import { PlayerScore } from './measures'

export type ReportRow = {
  userName: string
  gender: string
  teamName: string
  playerName: string
  weightDeltaFromStart: number
  totalPointsFromStart: number
  fromDate: string
  toDate: string
}

function getReportScores(
  score: PlayerScore
): Pick<ReportRow, 'weightDeltaFromStart' | 'totalPointsFromStart'> {
  return {
    weightDeltaFromStart: round(-score.weightDeltaFromStart, 2),
    totalPointsFromStart: round(score.totalPointsFromStart),
  }
}
function getReportDates(
  challenge: ChallengeInstance
): Pick<ReportRow, 'fromDate' | 'toDate'> {
  return {
    fromDate: formatDateShort(challenge.firstDay),
    toDate: formatDateShort(challenge.lastDay),
  }
}

export function generateTeamReport(
  user: UserInstance,
  team: TeamInstance
): ReportRow[] {
  const { challenge } = team
  if (!challenge) return []
  const lastDay = last(challenge.days)!

  const userName = user.name + ' ' + user.surname
  const teamName = team.name

  const dates = getReportDates(challenge)
  const playersRows: ReportRow[] = team.players.map((p) => {
    const scores = challenge.scoresByPlayers[p.id][lastDay.name]
    return {
      userName,
      teamName: teamName,
      playerName: p.name + ' ' + p.surname,
      gender: p.raw.gender ?? '',
      ...getReportScores(scores),
      ...dates,
    }
  })

  const userRow: ReportRow = {
    userName,
    teamName,
    playerName: userName,
    gender: user.raw?.gender ?? '',
    ...getReportScores(challenge.scoresUser[lastDay.name]),
    ...dates,
  }
  return [userRow, ...playersRows]
}

export function generateAllTeamsReport(
  user: UserInstance,
  teams: TeamInstance[]
): ReportRow[] {
  return teams.flatMap((t) => generateTeamReport(user, t))
}
