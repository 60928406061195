import dayjs from 'dayjs'
import { compact, max, round } from 'lodash'
import { GenericDate, toDate } from './dates-utils'

export function formatDate(date: Date): string {
  return date.toLocaleDateString('it-IT', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}
export function formatDay(date: Date): string {
  const str = date.toLocaleDateString('it-IT', {
    weekday: 'long',
  })

  return str
}

export function formatDateShort(date: GenericDate) {
  return dayjs(toDate(date)).format('DD/MM/YYYY')
}

export function formatWeightDelta(weight: number): string {
  if (weight === 0) return '0'

  const sign = weight < 0 ? '-' : '+'
  const absWeight = Math.abs(weight)
  const kg = Math.floor(absWeight)
  const g = Math.round((absWeight - kg) * 1000)
  return `${sign}${compact([kg !== 0 && `${kg}kg`, g !== 0 && `${g}g`]).join(
    ' '
  )}`
}

export function formatActivityPoint(activity: number): string {
  return `${Math.round(activity)}`
}
export function formatActivity(activity: number): string {
  return `${formatActivityPoint(activity)}🥇`
}

type FormatTableAlign = 'left' | 'right'
function pad(str: string, length: number, align: FormatTableAlign) {
  const count = length - charWidth(str)
  return align === 'left'
    ? str + ' '.repeat(count)
    : ' '.repeat(count) + str.padStart(length)
}

type FormatTableOptions = {
  separator?: string
  align?: FormatTableAlign[]
}

const CHART_WIDTHS: Record<string, number> = {
  ' ': 1,
  i: 1,
  j: 1,
  l: 1,
  I: 1,
  1: 1,
  '-': 1,
  '+': 1,
  0: 1.5,
  k: 1.5,
  '.': 2,
  ',': 2,
}
const DEFAULT_CHART_WIDTH = 2
function charWidth(str: string) {
  return str
    .split('')
    .reduce((acc, char) => acc + (CHART_WIDTHS[char] ?? DEFAULT_CHART_WIDTH), 0)
}

export function formatTable(
  table: string[][],
  { separator = '      ', align = [] }: FormatTableOptions = {}
): string {
  if (table.length === 0) return ''
  const widths = table[0].map(
    (_, i) => max(table.map((row) => charWidth(row[i]))) ?? 0
  )

  return table
    .map((row) =>
      row
        .map((cell, i) =>
          pad(
            cell,
            widths[i] - charWidth(cell) + charWidth(cell),
            align[i] ?? 'left'
          )
        )
        .join(separator)
        .concat('')
    )
    .join('\n')
}
