import { flow, Instance, types } from 'mobx-state-tree'
import { compact, groupBy, keyBy, mapValues } from 'lodash'
import Api, {
  DBChallenge,
  DbPlayer,
  DbTeam,
  DbUserInfo,
  UserData,
  WithId,
} from '../lib/Api'
import { ignoreFail, tick } from '../lib/async-utils'
import { afterCreate } from '../lib/mst-utils'
import { LoadingModel } from './Loading'
import { PlayerInstance, PlayerModel } from './Player'
import { TeamInstance, TeamModel } from './Team'
import { VolatileFirebaseUser } from './VolatileFirebaseUser'
import { Timestamp } from 'firebase/firestore'
import { StorageImage } from './StorageImage'
import { getToday } from '../lib/dates-utils'
import { compactObject } from '../lib/types-utils'

export const UserModel = types
  .model({
    loading: LoadingModel(),
    formMeasuresLoading: LoadingModel('success'),
    raw: types.frozen<DbUserInfo | undefined>(),
    players: types.array(PlayerModel),
    image: types.optional(StorageImage, {}),
    teams: types.array(TeamModel),
  })
  .extend(VolatileFirebaseUser)
  .views((self) => ({
    get _playersByTeam(): Record<string, PlayerInstance[] | undefined> {
      return groupBy(
        self.players.filter((d) => d.team),
        (d) => d.team?.id
      )
    },

    get name() {
      return self.raw?.name
    },

    get surname() {
      return self.raw?.surname
    },

    get weights() {
      return self.raw?.weight ?? {}
    },
  }))
  .actions((self) => ({
    setUserInfo(data: DbUserInfo) {
      self.raw = data
      self.image.setStoragePath(data.imageStoragePath)
    },
    addTeam({ id, ...raw }: WithId<DbTeam>) {
      self.teams.push({ id, raw })
    },

    addPlayer({ id, ...raw }: WithId<DbPlayer>) {
      self.players.push({
        id,
        raw,
        team: raw.team?.id,
        image: { storagePath: raw.imageStoragePath },
      })
    },
  }))
  .actions((self) => ({
    setUserData(data: UserData) {
      if (data.info) self.setUserInfo(data.info)
      data.players.forEach((player) => self.addPlayer(player))
      data.teams.forEach((team) => self.addTeam(team))
    },
  }))
  .actions((self) => ({
    fetchUserData: flow(function* () {
      try {
        self.loading.set('pending')
        const data: UserData = yield Api.getUserData()
        self.setUserData(data)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchCreateTeam: flow(function* ({ name }: { name: string }) {
      try {
        self.loading.set('pending')

        const teamData: DbTeam = {
          name,
          deleted: false,
          created: Timestamp.fromDate(getToday()),
        }

        const id: string = yield Api.createTeam(teamData)
        self.addTeam({ id, ...teamData })
        self.loading.set('success')

        return id
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchUpdateTeam: flow(function* (
      team: TeamInstance,
      props: {
        name: string
      }
    ) {
      const { name } = props
      try {
        self.loading.set('pending')

        const data: DbTeam = { ...team.raw, name }
        yield Api.setTeam(team.id, data)

        team.setRaw(data)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchStartTeamChallenge: flow(function* (
      team: TeamInstance,
      props: { duration: number; from: Date }
    ) {
      const { duration, from } = props
      try {
        self.loading.set('pending')

        const challenge: DBChallenge = {
          from: Timestamp.fromDate(from),
          duration,
        }

        const data: DbTeam = {
          ...team.raw,
          challenge,
        }

        yield Api.setTeam(team.id, data)
        team.setRaw(data)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchEndTeamChallenge: flow(function* (team: TeamInstance) {
      try {
        self.loading.set('pending')

        const data: DbTeam = {
          ...team.raw,
          challenge: null,
          previousChallenges: compact([
            ...(team.raw.previousChallenges ?? []),
            team.raw.challenge,
          ]),
        }

        yield Api.setTeam(team.id, data)
        team.setRaw(data)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchDeleteTeamChallenge: flow(function* (team: TeamInstance) {
      try {
        self.loading.set('pending')

        const data: DbTeam = {
          ...team.raw,
          challenge: null,
        }

        yield Api.setTeam(team.id, data)
        team.setRaw(data)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchCreatePlayer: flow(function* (data: {
      name: string
      surname: string
      teamId: string
      image?: Blob
      gender: string
      mail?: string
      phone?: string
    }) {
      const {
        name,
        surname,
        image,
        teamId,
        gender,
        mail = null,
        phone = null,
      } = data
      try {
        self.loading.set('pending')

        const created = Timestamp.fromDate(getToday())

        const imageStoragePath = image
          ? ((yield Api.uploadUserPic(image)) as string)
          : null

        const player: DbPlayer = {
          name,
          surname,
          team: { id: teamId, from: created },
          created,
          imageStoragePath,
          deleted: false,
          gender,
          mail,
          phone,
        }

        const id: string = yield Api.createPlayer(player)

        self.addPlayer({ id, ...player })
        self.loading.set('success')

        return id
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchUpdateUser: flow(function* (props: {
      name: string
      surname: string
      image?: Blob | string
      gender: string
    }) {
      const { name, surname, image, gender } = props
      try {
        self.loading.set('pending')

        const isSameImage = image === self.image?.url

        if (!isSameImage) {
          yield ignoreFail(Api.deleteUserBlob(self.image?.storagePath!))
        }

        const imageStoragePath = isSameImage
          ? self.image.storagePath
          : image instanceof Blob
          ? ((yield Api.uploadUserPic(image)) as string)
          : null

        const data: DbUserInfo = {
          imageStoragePath,
          name,
          surname,
          gender,
        }

        yield Api.setUserInfo(data)

        self.setUserInfo(data)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchUpdatePlayer: flow(function* (
      player: PlayerInstance,
      props: {
        name: string
        surname: string
        image?: Blob | string
        gender: string
        phone?: string
        mail?: string
      }
    ) {
      const { name, surname, image, gender, phone = null, mail = null } = props
      try {
        self.loading.set('pending')

        const isSameImage = image === player.image.url

        if (!isSameImage) {
          yield ignoreFail(Api.deleteUserBlob(player.image.storagePath!))
        }

        const imageStoragePath = isSameImage
          ? player.image.storagePath
          : image instanceof Blob
          ? ((yield Api.uploadUserPic(image)) as string)
          : null

        const data: DbPlayer = {
          ...player.raw,
          imageStoragePath,
          name,
          surname,
          gender,
          phone,
          mail,
        }

        yield Api.setPlayer(player.id, data)

        player.setRaw(data)
        player.image.setStoragePath(imageStoragePath)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),

    fetchUpdateTeamMeasures: flow(function* (
      userWeightsArr: {
        day: string
        value: number | null
      }[],

      weightsArr: {
        player: string
        day: string
        value: number | null
      }[],
      activitiesArr: {
        player: string
        day: string
        value: number | null
      }[]
    ) {
      const userWeights = mapValues(
        keyBy(userWeightsArr, 'day'),
        (d) => d.value
      )

      const weights = mapValues(groupBy(weightsArr, 'day'), (playerWeights) =>
        mapValues(keyBy(playerWeights, 'player'), (d) => d.value)
      )
      const activities = mapValues(
        groupBy(activitiesArr, 'day'),
        (playerActivities) =>
          mapValues(keyBy(playerActivities, 'player'), (d) => d.value)
      )

      try {
        self.formMeasuresLoading.set('pending')

        if (self.raw) {
          const user: DbUserInfo = {
            ...self.raw,
            weight: compactObject({
              ...(self.raw.weight ?? {}),
              ...userWeights,
            }),
          }
          yield Api.setUserInfo(user)
          self.setUserInfo(user)
        }

        // update player measures
        const playerById = keyBy(self.players, 'id')
        const playerIds = Object.keys(weights)
        for (const playerId of playerIds) {
          const player = playerById[playerId]

          const playerWeights = weights[playerId]
          const playerActivities = activities[playerId]

          const data: DbPlayer = {
            ...player.raw,
            weight: compactObject({
              ...(player.raw.weight ?? {}),
              ...playerWeights,
            }),
            activity: compactObject({
              ...(player.raw.activity ?? {}),
              ...playerActivities,
            }),
          }

          yield Api.setPlayer(playerId, data)
          player.setRaw(data)
        }

        self.formMeasuresLoading.set('success')
      } catch (e) {
        console.error(e)
        self.formMeasuresLoading.set('error')
      }
    }),

    fetchDeletePlayer: flow(function* (player: PlayerInstance) {
      try {
        self.loading.set('pending')

        yield Api.setPlayer(player.id, {
          ...player.raw,
          deleted: true,
          team: null,
          previousTeams: compact([
            ...(player.raw.previousTeams ?? []),
            player.raw.team
              ? { ...player.raw.team, to: Timestamp.fromDate(getToday()) }
              : null,
          ]),
        })
        self.players.remove(player)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),
  }))

  .actions((self) => ({
    fetchDeleteTeam: flow(function* (team: TeamInstance) {
      try {
        self.loading.set('pending')

        for (const player of team.players) {
          yield self.fetchDeletePlayer(player)
        }

        yield Api.setTeam(team.id, { ...team.raw, deleted: true })
        self.teams.remove(team)

        self.loading.set('success')
      } catch (e) {
        console.error(e)
        self.loading.set('error')
      }
    }),
  }))
  .actions(
    afterCreate(async (self) => {
      await tick() // wait for firebaseUser to be set
      await self.fetchUserData()
    })
  )

export interface UserInstance extends Instance<typeof UserModel> {}
