import { DeepPartial, FieldError, FieldValues, useForm } from 'react-hook-form'

function removeEmptyKeys<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter((o) => o[1] !== undefined && o[1] !== null)
  ) as T
}

export function useCoolForm<T extends FieldValues>(props: {
  defaultValues?: DeepPartial<T>
  errors: (data: T) => Record<keyof T, Partial<FieldError> | undefined>
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<T>({
    defaultValues: props.defaultValues,
    resolver: async (values) => {
      return {
        values,
        errors: removeEmptyKeys(props.errors(values)),
      }
    },
  })

  return { register, handleSubmit, errors }
}
