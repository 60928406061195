import { observer } from 'mobx-react-lite'
import React from 'react'
import { Loader } from '../ui/Loader'
import { useMst } from '../../state'

interface Props {
  children?: React.ReactNode
}

export const LoadingFallback = observer(function LoadingPage({
  children,
}: Props) {
  const { isUserChecked, user } = useMst()
  if (isUserChecked && (!user || user.loading.success)) return <>{children}</>
  return <Loader />
})
