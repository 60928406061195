import { Dictionary, first, last, sumBy, zipObject } from 'lodash'

export function sanitizeWeights(weights: (number | null)[]): number[] {
  const filledNext = weights.reduce((acc, curr) => {
    const prev = last(acc) ?? null
    acc.push(curr ?? prev)
    return acc
  }, [] as (number | null)[])

  const initialValue = first(filledNext.filter((w) => w !== null)) ?? 0

  return filledNext.map((w) => w ?? initialValue)
}

export function getDeltaWeights(weights: number[]): number[] {
  return weights.reduce((acc, curr, i, arr) => {
    const prev = arr[i - 1] ?? arr[i]
    acc.push(curr - prev)
    return acc
  }, [] as number[])
}

export function getDeltaWeightsFromStart(weights: number[]): number[] {
  // return an array of numbers representing the difference between each weight and the first weight
  return weights.map((w) => w - weights[0])
}

export function sanitizeActivity(activity: (number | null)[]): number[] {
  return activity.map((a) => a ?? 0)
}

export function getCumulativeActivity(activity: number[]): number[] {
  return activity.reduce((acc, curr) => {
    const prev = last(acc) ?? 0
    acc.push(prev + curr)
    return acc
  }, [] as number[])
}

export function calculateWeightPoints(weightDelta: number): number {
  return weightDelta * -10
}

export type PlayerScore = {
  weight: number
  weightDelta: number
  weightDeltaFromStart: number
  weightPoints: number
  weightPointsFromStart: number
  activity: number
  activityCumulative: number
  totalPoints: number
  totalPointsFromStart: number
}
export function computePlayerScore(
  days: string[],
  weightsByDay: Dictionary<number | null>,
  activityByDay: Dictionary<number | null>
): Dictionary<PlayerScore> {
  const weights = days.map((day) => weightsByDay[day] ?? null)
  const sanitizedWeights = sanitizeWeights(weights)
  const deltaWeights = getDeltaWeights(sanitizedWeights)
  const deltaWeightsFromStart = getDeltaWeightsFromStart(sanitizedWeights)

  const activity = days.map((day) => activityByDay[day] ?? null)
  const sanitizedActivity = sanitizeActivity(activity)
  const cumulativeActivity = getCumulativeActivity(sanitizedActivity)

  const weightPoints = deltaWeights.map(calculateWeightPoints)
  const weightPointsFromStart = deltaWeightsFromStart.map(calculateWeightPoints)

  return zipObject(
    days,
    days.map((_, i) => ({
      weight: sanitizedWeights[i],
      weightDelta: deltaWeights[i],
      weightDeltaFromStart: deltaWeightsFromStart[i],
      weightPoints: weightPoints[i],
      weightPointsFromStart: weightPointsFromStart[i],
      activity: sanitizedActivity[i],
      activityCumulative: cumulativeActivity[i],
      totalPoints: weightPoints[i] + sanitizedActivity[i],
      totalPointsFromStart: weightPointsFromStart[i] + cumulativeActivity[i],
    }))
  )
}

export type TeamScore = {
  weightDelta: number
  weightDeltaFromStart: number
  weightPoints: number
  weightPointsFromStart: number
  activity: number
  activityCumulative: number
  totalPoints: number
  totalPointsFromStart: number
}

export function computeTeamScore(
  days: string[],
  userScores: Dictionary<PlayerScore>,
  playerScores: Dictionary<Dictionary<PlayerScore>>
): Dictionary<TeamScore> {
  const teamScores = days.map((day) => {
    const playerScoresForDay = Object.values(playerScores).map((ps) => ps[day])
    const scores = [userScores[day], ...playerScoresForDay]

    const weightDelta = sumBy(scores, (d) => d.weightDelta)
    const weightDeltaFromStart = sumBy(scores, (d) => d.weightDeltaFromStart)

    const weightPoints = sumBy(scores, (d) => d.weightPoints)
    const weightPointsFromStart = sumBy(scores, (d) => d.weightPointsFromStart)

    const activity = sumBy(scores, (d) => d.activity)
    const activityCumulative = sumBy(scores, (d) => d.activityCumulative)

    const totalPoints = weightPoints + activity
    const totalPointsFromStart = weightPointsFromStart + activityCumulative

    return {
      weightDelta,
      weightDeltaFromStart,
      weightPoints,
      weightPointsFromStart,
      activity,
      activityCumulative,
      totalPoints,
      totalPointsFromStart,
    }
  })

  return zipObject(days, teamScores)
}
