import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Path } from '../lib/constants'
import { formatDate } from '../lib/format-utils'
import { useUser } from '../state'
import { TeamInstance } from '../state/Team'
import { Button } from './ui/Button'
import { ButtonWithDialog } from './ui/ButtonWithDialog'

export type ChallengeSectionProps = {
  team: TeamInstance
}

export const ChallengeSection = observer(({ team }: ChallengeSectionProps) => {
  const { challenge } = team
  const user = useUser()!

  if (!challenge) {
    return (
      <div className="space-y-4">
        <div>La maratona non è ancora iniziata </div>
        <div>
          <Link to={Path.startChallenge(team.id)}>
            <Button>Inizia</Button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-between">
      {challenge.isOver ? (
        <div className="space-y-4">
          <div>
            <div>La maratona di {challenge.duration} giorni è finita</div>
            <div>Inizio: {formatDate(challenge.firstDay)}</div>
            <div>Fine: {formatDate(challenge.lastDay)}</div>
          </div>
          <ButtonWithDialog
            dialog={{
              onConfirm: async () => user.fetchEndTeamChallenge(team),
              label: 'Sei sicuro di terminare la challenge?',
              confirmButton: { label: 'Termina' },
              cancelButton: { label: 'Annulla' },
            }}
          >
            Termina
          </ButtonWithDialog>
        </div>
      ) : challenge.isActive ? (
        <div>
          <div>La maratona è attiva</div>
          <div>Inizio: da {formatDate(challenge.firstDay)}</div>
          <div>
            Giorno {challenge.currentDay} di {challenge.duration}
          </div>
        </div>
      ) : (
        <div>La maratona inizierà {formatDate(challenge.firstDay)}</div>
      )}

      <div>
        <Link to={Path.editChallenge(team.id)}>
          <Button>Modifica</Button>
        </Link>
      </div>
    </div>
  )
})
