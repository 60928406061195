import { Dictionary, max, range, zipObject } from 'lodash'
import { getParent, getRoot, Instance, types } from 'mobx-state-tree'
import { StateInstance } from '.'
import { DBChallenge } from '../lib/Api'
import {
  getChallengeCurrentDay,
  getChallengeLastDay,
  isChallengeActive,
  isChallengeLastDay,
  isChallengeOver,
} from '../lib/data/challenge'
import {
  computePlayerScore,
  computeTeamScore,
  PlayerScore,
} from '../lib/data/measures'
import { addDays } from '../lib/dates-utils'
import { formatDateShort } from '../lib/format-utils'
import { PlayerInstance } from './Player'
import { TeamInstance } from './Team'
import { UserInstance } from './User'

export const ChallengeModel = types
  .model({
    raw: types.frozen<DBChallenge>(),
  })
  .actions((self) => ({
    setRaw(raw: DBChallenge) {
      self.raw = raw
    },
  }))
  .views((self) => ({
    get currentDay() {
      return getChallengeCurrentDay(self.raw)
    },
    get isLastDay() {
      return isChallengeLastDay(self.raw)
    },

    get firstDay() {
      return self.raw.from.toDate()
    },

    get lastDay() {
      return getChallengeLastDay(self.raw)
    },

    get duration() {
      return self.raw.duration
    },

    get isOver() {
      return isChallengeOver(self.raw)
    },

    get isActive() {
      return isChallengeActive(self.raw)
    },
  }))
  .views((self) => ({
    get days() {
      return range(-1, self.duration).map((i) => {
        const date = addDays(self.firstDay, i)
        return {
          number: i + 1,
          date,
          name: formatDateShort(date),
        }
      })
    },
  }))
  .views((self) => ({
    get scoresByPlayers() {
      const { players } = getParent<TeamInstance>(self)
      const days = self.days.map((d) => d.name)
      const playersId: string[] = players.map((p) => p.id)
      const playersWeights: Dictionary<PlayerScore>[] = players.map((p) => {
        const scores = computePlayerScore(days, p.weight, p.activity)
        return scores
      })

      return zipObject(playersId, playersWeights)
    },
  }))
  .views((self) => ({
    get scoresUser(): Dictionary<PlayerScore> {
      const { user } = getRoot<StateInstance>(self)
      const days = self.days.map((d) => d.name)
      return computePlayerScore(days, user?.weights ?? {}, {})
    },
  }))
  .views((self) => ({
    get scoresTeam() {
      const days = self.days.map((d) => d.name)
      return computeTeamScore(days, self.scoresUser, self.scoresByPlayers)
    },
    get topPerformersByDay(): Dictionary<{
      score: number
      players: (PlayerInstance | UserInstance)[]
    }> {
      const { user } = getRoot<StateInstance>(self)
      const { players } = getParent<TeamInstance>(self)
      const days = self.days.map((d) => d.name)

      const tops = days.map((day) => {
        const scores = [
          { score: self.scoresUser[day].totalPointsFromStart, player: user! },
          ...players.map((player) => ({
            score: self.scoresByPlayers[player.id][day].totalPointsFromStart,
            player,
          })),
        ]

        const score = max(scores.map((s) => s.score))!
        const topPlayers = scores
          .filter((s) => s.score === score)
          .map((s) => s.player)

        return { score, players: topPlayers }
      })

      return zipObject(days, tops)
    },
  }))

export interface ChallengeInstance extends Instance<typeof ChallengeModel> {}
