// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!)

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
auth.useDeviceLanguage()
auth.setPersistence(browserLocalPersistence)

const googleProvider = new GoogleAuthProvider()
export const signIn = (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password)
export const signInGoogle = () => signInWithPopup(auth, googleProvider)
export const signOut = () => auth.signOut()
export const resetPassword = (email = auth.currentUser?.email ?? '') =>
  sendPasswordResetEmail(auth, email)

export type FirebaseUser = User

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)

export const userId = () => {
  const uid = auth.currentUser?.uid
  if (!uid) throw Error('must be logged')
  return uid
}

export const storage = getStorage(app)
