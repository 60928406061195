import { Button, ButtonProps } from './Button'
import { Modal } from './Modal'

export interface DialogProps {
  label?: React.ReactNode
  children?: React.ReactNode

  confirmButton: Omit<ButtonProps, `on${string}`>
  cancelButton: Omit<ButtonProps, `on${string}`>
  onConfirm: () => void
  onCancel: () => void
}
export const Dialog = ({
  children,
  label,
  cancelButton,
  confirmButton,
  onCancel,
  onConfirm,
}: DialogProps) => {
  const handleConfirm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    onConfirm()
  }

  return (
    <Modal>
      <div
        className="w-full h-full flex justify-center items-center"
        onClick={onCancel}
      >
        <div className="bg-white p-10 flex justify-center flex-col items-center space-y-3">
          <div>
            <div>{label ?? children}</div>
          </div>
          <div className="space-x-3">
            <Button {...confirmButton} onClick={handleConfirm} />
            <Button {...cancelButton} onClick={onCancel} />
          </div>
        </div>
      </div>
    </Modal>
  )
}
