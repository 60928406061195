import { Instance, types } from 'mobx-state-tree'
import { DbPlayer } from '../lib/Api'
import { StorageImage } from './StorageImage'
import { TeamModel } from './Team'

export const PlayerModel = types
  .model({
    id: types.identifier,
    raw: types.frozen<DbPlayer>(),
    team: types.maybe(types.reference(TeamModel)),
    image: StorageImage,
  })
  .actions((self) => ({
    setRaw(raw: DbPlayer) {
      self.raw = raw
    },
  }))
  .views((self) => ({
    get name() {
      return self.raw.name
    },

    get surname() {
      return self.raw.surname
    },

    get weight() {
      return self.raw.weight ?? {}
    },

    get activity() {
      return self.raw.activity ?? {}
    },
  }))
export interface PlayerInstance extends Instance<typeof PlayerModel> {}
