import { observer } from 'mobx-react-lite'

import { globalState, StateProvider } from './state'
import { LoadingFallback } from './components/layout/LoadingFallback'
import { Routes } from './pages/Routes'

const App = observer(() => {
  return (
    <StateProvider value={globalState}>
      <LoadingFallback>
        <Routes />
      </LoadingFallback>
    </StateProvider>
  )
})

export default App
