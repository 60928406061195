import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { Header } from '../components/layout/Header'
import { Container } from '../components/ui/Container'
import { Title } from '../components/ui/Title'
import { useUser } from '../state'
import { LoadingFallback } from '../components/layout/LoadingFallback'
import { Path } from '../lib/constants'
import { TeamInstance } from '../state/Team'
import { FormTeam } from '../components/FormTeam'
import { ButtonDelete } from '../components/ui/ButtonDelete'
import { Page } from '../components/layout/Page'
import { Divider } from '../components/ui/Divider'

export const EditTeamPage = observer(() => {
  const { teamId } = useParams()
  const user = useUser()!
  const team = user.teams.find((p) => p.id === teamId)

  return (
    <LoadingFallback>
      <Page>
        <Header />

        {team ? (
          <EditTeamPageContent team={team} />
        ) : (
          <Container>Ops, la squadra non esiste</Container>
        )}
      </Page>
    </LoadingFallback>
  )
})

interface EditTeamPageContentProps {
  team: TeamInstance
}

export const EditTeamPageContent = observer(
  ({ team }: EditTeamPageContentProps) => {
    const user = useUser()!
    const navigate = useNavigate()

    return (
      <Container>
        <Title>Modifica la informazioni della squadra</Title>

        <div className="space-y-4">
          <FormTeam
            name={team.name}
            labelConfirm="Salva"
            onCreate={async (data) => {
              await user.fetchUpdateTeam(team, data)
              navigate(Path.team(team.id))
            }}
          />

          <Divider />

          <ButtonDelete
            onDelete={async () => {
              await user.fetchDeleteTeam(team)
              navigate(Path.user)
            }}
          />
        </div>
      </Container>
    )
  }
)
